<template>
  <div>
    <section id="section0" class="h-viewport">
      <video
        id="video-bg"
        ref="video"
        autoplay
        muted
        loop
        class="section-video-bg"
      >
        <source
          class="active"
          src="/images/anlly/v_hk_2.mp4"
          type="video/mp4"
        />
        <!-- <source src="/images/anlly/v_hk.mp4" type="video/mp4" /> -->
        <!-- <source src="/images/anlly/v_sh.mp4" type="video/mp4" /> -->
        <!-- <source src="/images/anlly/v_sf.mp4" type="video/mp4" /> -->
      </video>
      <div class="v-graph">
        <div class="g-b0">
          <div class="g-b0-0"></div>
        </div>
        <div class="g-b1"></div>
        <div class="g-b2"></div>
        <div class="g-b3"></div>
      </div>
      <div class="h-100">
        <div
          class="container h-100 section-dynamic align-items-center"
          style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"
        >
          <div
            class="section-content pt-5 p-4"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="50"
            data-aos-offset="0"
          >
            <h1>
              安立资管是一家具有全球视野
              <div class="w-100 d-none d-md-block"></div>
              并聚焦大湾区的资产管理公司
            </h1>
            <div class="text-block">
              <p style="margin-top:23px;margin-bottom:10px">持有香港证监会 4 号和 9 号金融牌照，为客户提供证券交易意见和资产管理（包括基金设立及管理、私人银行外部资产管理 EAM、全权委托投资专户）等服务。</p>
<p>投资领域涵盖一二级市场股权项目、传统及另类债权项目、各类型基金及数字货币。以跨市场资产配置和多元化投资能力，致力帮助客户分散投资风险，提高潜在回报。</p>

            </div>
            <div style="height: 30px; width: 1px"></div>
          </div>
        </div>
      </div>
      <div class="page-down">
        <a href="#section-company">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M12.51,8.7,10,11,7.49,8.7a.51.51,0,0,0-.72,0,.5.5,0,0,0,0,.71l2.86,2.81a.51.51,0,0,0,.72,0l2.86-2.81a.5.5,0,0,0,0-.71A.51.51,0,0,0,12.51,8.7ZM10,.4A9.6,9.6,0,1,0,19.6,10,9.6,9.6,0,0,0,10,.4Zm0,18A8.35,8.35,0,1,1,18.35,10,8.35,8.35,0,0,1,10,18.35Z"
              fill="#FFFFFFAA"
            ></path>
          </svg>
        </a>
      </div>
    </section>

    <section id="section-company" class="secondary h-viewport">
      <div class="container h-100 section-dynamic align-items-center">
        <div
          class="mh-650 section-content"
          data-aos="fade-up"
          data-aos-easing="ease-in-back"
          data-aos-anchor="#section-company"
          data-aos-duration="1100"
          data-aos-offset="0"
          data-aos-anchor-placement="top-center"
        >
          <div class="col-md-12">
            <h1 class="v-bios-heading">公司标志理念</h1>
          </div>
          <div class="col-md-12">
            <div class="row" style="text-align: justify; margin-top: 1.5rem">
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div
                  style="
                    margin: 0.5rem;
                    background: rgba(221, 236, 255, 0.6);
                    min-height: 9rem;
                    padding: 1rem;
                  border-radius: 0 0.5rem 0.5rem 0.5rem;
                  border-left: 15px solid #1F63AF;
                  "
                >
                  <h5>
                    结合安立企业理念「安若泰山，建功立业」，公司商标 A
                    字的设计与安立首字母A相融，与安若泰山契合;
                    象征企业积极进取，攀登高峰。
                  </h5>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div
                  style="
                    margin: 0.5rem;
                    background: rgba(221, 236, 255, 0.6);
                    min-height: 9rem;
                    padding: 1rem;
                  border-radius: 0 0.5rem 0.5rem 0.5rem;
                  border-left: 15px solid #1F63AF;
                  "
                >
                  <h5>
                    使用火箭元素，火箭是实现航天飞行军载工具，象征安立像火箭一样，蓬勃向上，势不可挡。
                  </h5>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div
                  style="
                    margin: 0.5rem;
                    background: rgba(221, 236, 255, 0.6);
                    min-height: 9rem;
                    padding: 1rem;
                  border-radius: 0 0.5rem 0.5rem 0.5rem;
                  border-left: 15px solid #1F63AF;
                  "
                >
                  <h5>
                    回旋标容易形成自旋而具有较好的稳定性，留空时间更长而且更容易命中目标，寓意安立重心稳定，在工作、交易中策无遗算。
                  </h5>
                </div>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
                <div
                  style="
                    margin: 0.5rem;
                    background: rgba(221, 236, 255, 0.6);
                    height: 9rem;
                    padding: 1rem;
                  border-radius: 0 0.5rem 0.5rem 0.5rem;
                  border-left: 15px solid #1F63AF;
                  "
                >
                  <h5>
                    采用交叉形式外观，展现楼宇坐落交错的景观，是一个城市相互交织在一起的网络系統，寓意安立的专业化、多元化。
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-down">
        <a href="#section-ps">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M12.51,8.7,10,11,7.49,8.7a.51.51,0,0,0-.72,0,.5.5,0,0,0,0,.71l2.86,2.81a.51.51,0,0,0,.72,0l2.86-2.81a.5.5,0,0,0,0-.71A.51.51,0,0,0,12.51,8.7ZM10,.4A9.6,9.6,0,1,0,19.6,10,9.6,9.6,0,0,0,10,.4Zm0,18A8.35,8.35,0,1,1,18.35,10,8.35,8.35,0,0,1,10,18.35Z"
              fill="#FFFFFFAA"
            ></path>
          </svg>
        </a>
      </div>
    </section>

    <section id="section-ps" class="secondary h-viewport">
      <div id="products" class="container h-100 section-dynamic align-items-center">
        <div
          class="mh-650 section-content"
          data-aos="fade-up"
          data-aos-easing="ease-in-back"
          data-aos-anchor="#section-ps"
          data-aos-duration="1100"
          data-aos-offset="0"
          data-aos-anchor-placement="top-center"
        >
          <div class="col-md-12">
            <h1 class="v-bios-heading">安立产品与服务</h1>
          </div>
          <div class="col-md-12 row">
            <div
              v-for="(p, i) in products"
              :key="p.name"
              class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
            >
              <a
                data-aos="fade-up"
                data-aos-easing="ease-in-back"
                :data-aos-delay="200 * i"
                data-aos-duration="1400"
                data-aos-offset="0"
                v-on:click="productSelected = 'p' + i"
                :href="`/#section-p${i}`"
                :style="`
                  text-decoration: none;
                  background-image: url(${p.bg_url});
                  background: white;
                  border-radius: 0 0.5rem 0.5rem 0.5rem;
                  border-left: 15px solid;
                  counter-increment: gradient-counter;
                  margin-top: 1rem;
                  padding: 1rem 0.5rem 1rem 2rem;
                  position: relative;
                  display: block;
                  /*margin-right: ${0.5 * i}rem;*/
                `"
              >
                <h5 style="margin: 0.2rem 0">{{ p.name }}</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="page-down">
        <a href="#section1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M12.51,8.7,10,11,7.49,8.7a.51.51,0,0,0-.72,0,.5.5,0,0,0,0,.71l2.86,2.81a.51.51,0,0,0,.72,0l2.86-2.81a.5.5,0,0,0,0-.71A.51.51,0,0,0,12.51,8.7ZM10,.4A9.6,9.6,0,1,0,19.6,10,9.6,9.6,0,0,0,10,.4Zm0,18A8.35,8.35,0,1,1,18.35,10,8.35,8.35,0,0,1,10,18.35Z"
              fill="#FFFFFFAA"
            ></path>
          </svg>
        </a>
      </div>
    </section>

    <PageSection
      id="section-p0"
      bg="/images/anlly/products/p_gcb.jpg"
      back-button="true"
      v-if="productSelected === 'p0'"
      fixed
    >
      <div
        class="container h-100 section-dynamic align-items-center"
        style="position: relative"
      >
        <!-- <div class="page-back">
          <a href="#products" v-on:click="productSelected = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                transform="rotate(90 10 10)"
                id="svg_1"
                fill="#FFFFFFAA"
                d="m12.51,8.7l-2.51,2.3l-2.51,-2.3a0.51,0.51 0 0 0 -0.72,0a0.5,0.5 0 0 0 0,0.71l2.86,2.81a0.51,0.51 0 0 0 0.72,0l2.86,-2.81a0.5,0.5 0 0 0 0,-0.71a0.51,0.51 0 0 0 -0.7,0zm-2.51,-8.3a9.6,9.6 0 1 0 9.6,9.6a9.6,9.6 0 0 0 -9.6,-9.6zm0,18a8.35,8.35 0 1 1 8.35,-8.4a8.35,8.35 0 0 1 -8.35,8.35l0,0.05z"
              />
            </svg>
          </a>
        </div> -->
        <div
          class="mh-650 section-content"
          data-aos="fade-up"
          data-aos-easing="ease-in-back"
          data-aos-anchor="#section-p0"
          data-aos-duration="1100"
          data-aos-offset="0"
          data-aos-anchor-placement="top-center"
        >
          <div class="position-absolute v-btnback">
            <a href="#products"  v-on:click="productSelected = false"> &lt; 返回 </a>
            <span>收益基金</span>
          </div>
          <div class="col-md-12">
            <h1 class="v-bios-heading">大中华探索收益基金</h1>
            <div class="text-block" style="margin-top: 1.5em">
              本基金主要投资于不同类型的应收账款融资及供应链金融项目,企业将赊销而形成的应收账款有条件地转让给专门的融资机构，使企业得到所需资金，加强资金。所投资的应收款除了高度单一化、商户信用度高、单笔金额集中度风险低、还有坏账率较低及催收较易。同时，基于应收款的季节性，本基金也会投资于美元私募债项目，主要是中国最具实力和排名前50強的头部房地产开发商，以充分利用资本提高回报。对比公募债，私募债的年化回报通常較高，预期整体回报达到双位数字。​
              ​
              <!-- 本基金主要投资于不同类型的应收账款融资及供应链金融项目。所投资的应收款除了高度单一化、商户信用度高、单笔金额集中度风险低、还有坏账率较低及催收较易。同时，基于应收款的季节性，本基金同時也会投资于美元私募债项目，主要是排名前50強的头部地产公司，以充分利用资本提高回报。 -->
            </div>
          </div>
        </div>
      </div>
    </PageSection>
    <PageSection
      id="section-p1"
      bg="/images/anlly/products/p_ipo.jpg"
      back-button="true"
      v-if="productSelected === 'p1'"
      fixed
    >
      <div
        class="container h-100 section-dynamic align-items-center"
        style="position: relative"
      >
        <!-- <div class="page-back">
          <a href="#products" v-on:click="productSelected = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                transform="rotate(90 10 10)"
                id="svg_1"
                fill="#FFFFFFAA"
                d="m12.51,8.7l-2.51,2.3l-2.51,-2.3a0.51,0.51 0 0 0 -0.72,0a0.5,0.5 0 0 0 0,0.71l2.86,2.81a0.51,0.51 0 0 0 0.72,0l2.86,-2.81a0.5,0.5 0 0 0 0,-0.71a0.51,0.51 0 0 0 -0.7,0zm-2.51,-8.3a9.6,9.6 0 1 0 9.6,9.6a9.6,9.6 0 0 0 -9.6,-9.6zm0,18a8.35,8.35 0 1 1 8.35,-8.4a8.35,8.35 0 0 1 -8.35,8.35l0,0.05z"
              />
            </svg>
          </a>
        </div> -->
        <div
          class="mh-650 section-content"
          data-aos="fade-up"
          data-aos-easing="ease-in-back"
          data-aos-anchor="#section-p1"
          data-aos-duration="1100"
          data-aos-offset="0"
          data-aos-anchor-placement="top-center"
        >
          <div class="position-absolute v-btnback">
            <a href="#products"  v-on:click="productSelected = false"> &lt; 返回 </a>
            <span style="background: rgba(89, 119, 176, 0.7); margin-left: -4px"
              >股权基金</span
            >
          </div>
          <div class="col-md-12">
            <h1 class="v-bios-heading">Pre-IPO／IPO基金</h1>
            <div class="text-block" style="margin-top: 1.5em">
              环球经济复苏，市场气氛转趋活跃，越来越多投资者正重拾对认购新股的兴趣。香港和美国为全球领先的
              IPO 集资地，很多优秀知名企业选择在香港或美国发行新股。本基金主要参与市面上各行业独角兽或龙头的IPO或Pre-IPO项目，投资于将向或已向交易所提交上市申请的企业，构建投资组合，
              其退出方式在上市后，从公开资本市场出售股票退出。我们团队拥有强大企业网络从而能成功网罗优质的项目，
              以机构投资者的形式参与招股，在交易成本、分配率，以至信息上均较散户占优。​
              <!-- 环球经济复苏，市场气氛转趋活跃，越来越多投资者正重拾对认购新股的兴趣。香港和美国为全球领先的
              IPO
              集资地，很有优秀知名企业选择在香港或美国发行新股。本基金将主要投资于企业上市之前，或预期企业可近期上市时，其退出方式在上市后，从公开资本市场出售股票退出。本基金同时亦会投资于港股或美股（IPO）股票，
              以机构投资者的形式参与招股，在交易成本、配额，以至信息上均较散户占优。 -->
            </div>
          </div>
        </div>
      </div>
    </PageSection>
    <PageSection
      id="section-p2"
      bg="/images/anlly/products/p_mv.jpg"
      back-button="true"
      v-if="productSelected === 'p2'"
      fixed
    >
      <div
        class="container h-100 section-dynamic align-items-center"
        style="position: relative"
      >
        <!-- <div class="page-back">
          <a href="#products"  v-on:click="productSelected = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                transform="rotate(90 10 10)"
                id="svg_1"
                fill="#FFFFFFAA"
                d="m12.51,8.7l-2.51,2.3l-2.51,-2.3a0.51,0.51 0 0 0 -0.72,0a0.5,0.5 0 0 0 0,0.71l2.86,2.81a0.51,0.51 0 0 0 0.72,0l2.86,-2.81a0.5,0.5 0 0 0 0,-0.71a0.51,0.51 0 0 0 -0.7,0zm-2.51,-8.3a9.6,9.6 0 1 0 9.6,9.6a9.6,9.6 0 0 0 -9.6,-9.6zm0,18a8.35,8.35 0 1 1 8.35,-8.4a8.35,8.35 0 0 1 -8.35,8.35l0,0.05z"
              />
            </svg>
          </a>
        </div> -->
        <div
          class="mh-650 section-content"
          data-aos="fade-up"
          data-aos-easing="ease-in-back"
          data-aos-anchor="#section-p2"
          data-aos-duration="1100"
          data-aos-offset="0"
          data-aos-anchor-placement="top-center"
        >
          <div class="position-absolute v-btnback">
            <a href="#products"  v-on:click="productSelected = false"> &lt; 返回 </a>
            <span>移民基金</span>
          </div>
          <div class="col-md-12">
            <!-- <h1 class="v-bios-heading">葡萄牙移民项目基金</h1> -->
            <h1 class="v-bios-heading">移民基金</h1>
            <div class="text-block" style="margin-top: 1.5em">
              自去年本港爆发社会运动事件后，移民海外的意欲大增，
              葡萄牙是新兴的移民国家，移民门坎相对其他欧洲城市低。非欧盟国家公民可通过资本投资方式获得葡萄牙「黄金签证」，利用国外资金在葡萄牙投资基金或风险投资基金至少35万欧元(最少60%的投资用于总部位于葡萄牙的商业公司)，
              身份可通行欧洲，每年只需居留7天，五年后即可申请永居葡萄牙黄金签证，同时成为欧盟公民。而安立资管正在向葡萄牙证券市场委员会（CMVM）申请基金管理牌照，以便可以直接管理葡萄牙的基金，
              满足投资者移民需要。​
              <!-- 非欧盟国家公民可通过资本投资方式获得葡萄牙「黄金签证」。依据此法案申请黄金签证的非欧盟国家公民，利用国外资金在葡萄牙投资基金或风险投资基金至少35万欧元(最少60%的投资用于总部位于葡萄牙的商业公司)，
              其投资期限至少是 5
              年。而安立资管正在向葡萄牙证券市场委员会（CMVM）申请基金管理牌照，以便可以直接管理葡萄牙的基金，
              满足投资者移民需要。 -->
            </div>
            <!-- <div class="row">
              <div class="col-md-6 col-sm-12 col-xs-12">
                <ol class="gradient-list">
                  <li>
                    基金认购操作简单，费用清晰透明；(基金收费用已含所有移民所需费用，如律师费等)
                  </li>
                  <li>投资配置多元化，有效分散风险；</li>
                  <li>监管机制健全，安全省心；</li>
                  <li>专业金融团队负责管理，安全稳健；</li>
                </ol>
              </div>
              <div class="col-md-6 col-sm-12 col-xs-12">
                <ol class="gradient-list" style="--start: 5" start="5">
                  <li>投资团队有丰富的当地经验和资深的市场知识；</li>
                  <li>可以供当地有关税务咨询服务；</li>
                  <li>透过投资满足移民需求， 同时可以增值资产， 一举两得；</li>
                  <li>
                    一条龙服务使客户安心又放心，团队由移民律师
                    及顾问处理各类申请，同时提供合适投资项目。
                  </li>
                </ol>
              </div>
            </div>
            <div class="row align-items-center visible-xs"></div> -->
          </div>
        </div>
      </div>
    </PageSection>
    <PageSection
      id="section-p3"
      bg="/images/anlly/products/s1_sh.jpg"
      back-button="true"
      v-if="productSelected === 'p3'"
      fixed
    >
      <div
        class="container h-100 section-dynamic align-items-center"
        style="position: relative"
      >
        <!-- <div class="page-back">
          <a href="#products"  v-on:click="productSelected = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                transform="rotate(90 10 10)"
                id="svg_1"
                fill="#FFFFFFAA"
                d="m12.51,8.7l-2.51,2.3l-2.51,-2.3a0.51,0.51 0 0 0 -0.72,0a0.5,0.5 0 0 0 0,0.71l2.86,2.81a0.51,0.51 0 0 0 0.72,0l2.86,-2.81a0.5,0.5 0 0 0 0,-0.71a0.51,0.51 0 0 0 -0.7,0zm-2.51,-8.3a9.6,9.6 0 1 0 9.6,9.6a9.6,9.6 0 0 0 -9.6,-9.6zm0,18a8.35,8.35 0 1 1 8.35,-8.4a8.35,8.35 0 0 1 -8.35,8.35l0,0.05z"
              />
            </svg>
          </a>
        </div> -->
        <div
          class="mh-650 section-content"
          data-aos="fade-up"
          data-aos-easing="ease-in-back"
          data-aos-anchor="#section-p3"
          data-aos-duration="1100"
          data-aos-offset="0"
          data-aos-anchor-placement="top-center"
        >
          <div class="position-absolute v-btnback">
            <a href="#products"  v-on:click="productSelected = false"> &lt; 返回 </a>
            <!-- <a href="javascript:void(0)"> 安立服務 </a> -->
            <!-- <span></span> -->
          </div>
          <div class="col-md-12">
            <h1 class="v-bios-heading">独特管道供境外境内投资</h1>
            <div class="row">
              <div class="v-bios col-md-5">
                <h4>QFLP基金</h4>
                <h5>
                  QFLP（Qualified Foreign Limited
                  Partner，合格境外有限合伙人，即股权基金的出资人）
                </h5>
                <div class="text-block">
                  是指境外机构投资者在通过资格审批和其外汇资金的监管程序后，将境外资本兑换为人民币资金，投资于国内的PE（私募股权投资）以及VC（风险投资）市场。
                </div>
              </div>
              <div class="v-bios col-md-5">
                <h4>QDIE基金</h4>
                <h5>
                  QDIE (Qualified Domestic Investment
                  Enterprise，合格境内投资企业)
                </h5>
                <div class="text-block">
                  是指在人民币资本项自由兑换尚未实现之时，符合条件投资管理机构经中国有关部门批准，面向境内投资者募集资金，对境外投资标的进行投资。
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="row align-items-center visible-xs"></div>
          </div>
        </div>
      </div>
    </PageSection>
    <PageSection
      id="section-p4"
      bg="/images/anlly/products/s2_sw.jpg"
      back-button="true"
      v-if="productSelected === 'p4'"
      fixed
    >
      <div
        class="container h-100 section-dynamic align-items-center"
        style="position: relative"
      >
        <!-- <div class="page-back">
          <a href="#products"  v-on:click="productSelected = false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path
                transform="rotate(90 10 10)"
                id="svg_1"
                fill="#FFFFFFAA"
                d="m12.51,8.7l-2.51,2.3l-2.51,-2.3a0.51,0.51 0 0 0 -0.72,0a0.5,0.5 0 0 0 0,0.71l2.86,2.81a0.51,0.51 0 0 0 0.72,0l2.86,-2.81a0.5,0.5 0 0 0 0,-0.71a0.51,0.51 0 0 0 -0.7,0zm-2.51,-8.3a9.6,9.6 0 1 0 9.6,9.6a9.6,9.6 0 0 0 -9.6,-9.6zm0,18a8.35,8.35 0 1 1 8.35,-8.4a8.35,8.35 0 0 1 -8.35,8.35l0,0.05z"
              />
            </svg>
          </a>
        </div> -->
        <div
          class="mh-650 section-content"
          data-aos="fade-up"
          data-aos-easing="ease-in-back"
          data-aos-anchor="#section-p4"
          data-aos-duration="1100"
          data-aos-offset="0"
          data-aos-anchor-placement="top-center"
        >
          <div class="position-absolute v-btnback">
            <!-- <a href="/#section-services"> 安立服務 </a> -->
            <a href="#products"  v-on:click="productSelected = false"> &lt; 返回 </a>
            <!-- <span></span> -->
          </div>
          <div class="col-md-12">
            <h1 class="v-bios-heading">外部资产管理人业务</h1>
            <div class="row">
              <div class="v-bios col-md-12">
                <div class="text-block">
                  安立资管与多间顶级私人银行合作，
                  作为私人资产管理业务外部管理人，透过各类型金融投资产品，
                  连手为全球超高净值人士及跨代富裕家庭管理财富及制定多元资产配置方案。外部资产管理人（EAM，External Asset Management）是私人银行业内的持份者，能作为第三方平台，正好充当了高净值客户的投资专业团队，免去不少与金融机构周旋的时间和精力，也为他们解决了财富管理的烦恼。EAM协助客户在托管银行（私人银行）开立帐户，然后将资产放入该帐户，客户以第三方的身份授予EAM授权和委托书，以代表他们管理投资组合和资产配置。​
                  <!-- 外部资产管理人（EAM， External Asset
                    Management）是私人银行业内的持份者，能作为第三方平台，正好充当了高净值客户的投资专业团队，免去不少与金融机构周旋的时间和精力，也为他们解决了财富管理的烦恼。EAM协助客户在托管银行（私人银行）开立帐户，然后将资产放入该帐户，客户以第三方的身份授予EAM授权和委托书，以代表他们管理投资组合和资产配置，资产始终以客户的名义保留在帐户中，但是EAM会协助客户管理其资产。简单来说，EAM依托私人银行平台，开展个性化更强、独立性更佳的私人财富管理人服务。
                  </div>
                  <br /><br />
                  <div class="text-block">
                    <b>安立资管与私人银行合作</b>，
                    作为私人资产管理业务外部管理人，透过各类型金融投资产品，
                    连手为全球超高净值人士及跨代富裕家庭管理财富及制定多元资产配置方案。 -->
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="row align-items-center visible-xs"></div>
          </div>
        </div>
      </div>
    </PageSection>

    <PageSection
      id="section1"
      bg="/images/anlly/DSC08834_com.png"
      next-section="#section-leader"
    >
      <div id="leaders" class="h-100">
        <div class="container h-100 section-dynamic align-items-center">
          <div
            class="mh-650 section-content row"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-anchor="#section1"
            data-aos-duration="1100"
            data-aos-offset="0"
            data-aos-anchor-placement="top-center"
          >
            <div class="col-md-12">
              <h1 class="v-bios-heading">领导团队</h1>
            </div>
            <div class="v-bios col-lg-6 col-md-6 col-sm-6 col-xs-12">              <!-- <img src="/images/anlly/bios/Job_0693.png" /> -->
              <h4>林贻贤</h4>
              <h5>创始人及行政总裁</h5>
              <div class="text-block" style="width: 100%">
                <!-- 任旭阳先生于 2001 年加入百度，为百度创业元老，曾任百度市场与商务拓展副总裁，负责公司市场、公关、战略合作、投资并购、政府关系和国际拓展方面工作，直接向 CEO 汇报。
2010 年 6 月，任旭阳离开百度，先赴斯坦福大学攻读管理学硕士，毕业后开始独立创业。任旭阳先生现任天善资本创始合伙人及董事长， 同时是互联网领域的连续创业者和天使投资人，曾先后牵头创办爱奇艺和一点资讯并担任董事至今。 -->
                林先生毕业于加拿大曼尼托巴大学，获院长荣誉及工商管理荣誉学士，主修金融及会计。于金融行业有近三十年的资深经验，荣获Asiamoney 2001和 2004年最佳机构销售员。曾服务过的公司包括：未来资产证券、现代证券、东洋证券和诚泰资本。负责机构销售、上市公司发行债券和可转换债券、私募股权投资、地产项目融资、收购合并和IPO。参与过的项目包括小米、美团及ESR(上市承销）；滴滴出行、大疆和商汤（股权投资）；香港中环中心（并购融资）。​
                <!-- 
                林先生毕业于加拿大曼尼托巴大学，获院长荣誉及工商管理荣誉学士，主修金融及会计。于金融行业有近三十年的资深经验，
                荣获Asiamoney 2001和 2004年最佳机构销售员。
                曾服务过的公司包括：未来资产证券、现代证券、东洋证券和诚泰资本。负责机构销售、上市公司发行债券和可转换债券、私募股权投资、地产项目融资、收购合并和IPO。参与过的项目包括小米、美团及ESR
                (上市承销）；滴滴出行、大疆和商汤（股权投资）；香港中环中心（并购融资）。 -->
              </div>
            </div>
            <div class="v-bios col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <!-- <img src="/images/anlly/bios/Job_0778.png" /> -->
              <h4>司徒健欣，CFA， FRM</h4>
              <h5>董事总经理</h5>
              <div class="text-block" style="width: 100%">
                司徒先生是一位经验丰富的交易员，专门从事高中频量化策略。他在构建和执行交易策略方面具丰富经验，包括算法逻辑的实施、回溯测试、优化建模和交易自动化。他曾于南韩一所交易量排名前三的自营交易公司以及香港一家族办公室设立了系统交易的部门，并于2018年成立了一支加密货币的开曼群岛的量化基金。司徒先生是特许金融分析师（CFA）、金融风险管理师（FRM）和北美精算师协会准精算师（ASA）。他毕业于香港大学，获得精算学学士学位。​

                <!-- 司徒先生是一位经验丰富的交易员，专门从事高中频量化策略。他在构建和执行交易策略方面具丰富经验，包括算法逻辑的实施、回溯测试、优化建模和交易自动化。他曾于南韩一所交易量排名前三的自营交易公司以及香港一家族办公室设立了系统交易的部门，并于2018年成立了一支加密货币的开曼群岛的量化基金。司徒先生是特许金融分析师（CFA）、金融风险管理师（FRM）和北美精算师协会准精算师（ASA）。他毕业于香港大学，获得精算学学士学位。 -->
              </div>
            </div>
            <!-- <div class="col-md-2"></div> -->
            <!-- <div class="col-md-12" style="height: 100px"></div> -->
          </div>
        </div>
      </div>
    </PageSection>
    <PageSection
      id="section-leader"
      bg="/images/anlly//DSC08827_com.png"
      next-section="#section-contact"
    >
      <div class="h-100">
        <div class="container h-100 section-dynamic align-items-center">
          <div
            class="mh-650 section-content row"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-anchor="#section-leader"
            data-aos-duration="1100"
            data-aos-offset="0"
            data-aos-anchor-placement="top-center"
          >
            <div class="col-md-12">
              <h1 class="v-bios-heading">基金顾问团队</h1>
            </div>
            <div class="v-bios col-lg-6 col-md-6 col-sm-6 col-xs-12">  
              <!-- <img src="/images/anlly/bios/Job_0693.png" /> -->
              <h4>任旭阳</h4>
              <h5>投资顾问</h5>
              <div class="text-block" style="width: 100%">
                任旭阳先生于 2001 年加入百度，为百度创业元老，曾任百度市场与商务拓展副总裁，负责公司市场、公关、战略合作、投资并购、政府关系和国际拓展方面工作，直接向 CEO 汇报。2010 年 6 月，任旭阳离开百度，先赴斯坦福大学攻读管理学硕士，毕业后开始独立创业。任旭阳先生现任天善资本创始合伙人及董事长， 同时是互联网领域的连续创业者和天使投资人，曾先后牵头创办爱奇艺和一点资讯并担任董事至今。
                <!-- 任旭阳先生2001年加入百度，工作长达十年，曾任百度市场与商务拓展副总裁，负责公司市场、公关、战略合作、投资并购、政府关系和国际拓展方面工作，直接向CEO汇报。2010年6月，任旭阳离开百度，先赴斯坦福大学攻读管理学硕士，毕业后开始独立创业。任旭阳先生现任天善资本创始合伙人及董事长，
                同时是互联网领域的连续创业者和天使投资人，曾先后牵头创办爱奇艺和一点资讯并担任董事至今。​ -->
                <!-- 任旭阳2001年加入百度，工作长达十年，曾任百度市场与商务拓展副总裁，负责公司市场、公关、战略合作、投资并购、政府关系和国际拓展方面工作，直接向CEO汇报。2010年6月，任旭阳离开百度，先赴斯坦福大学攻读管理学硕士，毕业后开始独立创业。十年下来，任旭阳先后和他的搭档们联合创立了一点资讯、News
                Break、海致科技等三家知名公司，以及专注A轮投资的天善资本。2016年，任旭阳还兼任了百度首席顾问。同時作为一点资讯、News
                Break、海致科技等多家知名公司的创始人。 -->
              </div>
            </div>
            <div class="v-bios col-lg-6 col-md-6 col-sm-6 col-xs-12">  
              <!-- <img src="/images/anlly/bios/Job_0778.png" /> -->
              <h4>林 雄</h4>
              <h5>投资顾问</h5>
              <div class="text-block" style="width: 100%">
                林雄先生毕业于美国罗格斯大学，主修会计学，获工商管理荣誉学士学位。美国注册会计师。曾任港交所上市委员会委员。世界知名邓普顿资产管理有限公司联合创始人，拥有超过三十年资产管理经验。曾为邓普顿亚洲增长基金、邓普顿新兴
                市场投资信托基金和其他新兴市场投资组合的独家经理，管理总规模达250亿美元，曾连续多年荣获多项杰出奖项， 团队遍布全球18家研究机构。​
                <!-- 
                林雄先生曾任港交所上市委员会委员。世界知名邓普顿资产管理有限公司联合创始人，拥有超过三十年资产管理经验。曾为邓普顿亚洲增长基金、邓普顿新兴F市场投资信托基金和其他新兴市场投资组合的独家经理，管理总规模达250亿美元。团队遍布全球18家研
                究机构。<br /><br />
                林雄先生曾荣获多项杰出奖项： <br />
                <ul class="v-simplelist">
                  <li>
                    Lippers -
                    2005、2006、2007、2008、2011、2012、2013和2015年度最佳亚洲基金
                  </li>
                  <li>
                    标准普尔 -
                    2003年最佳新兴市场基金；2003年和2010年最佳亚洲基金
                  </li>
                  <li>2007年最佳大型信托</li>
                  <li>路透社 - 香港和中国地区最佳个人基金经理</li>
                </ul> -->
              </div>
            </div>

            <!-- 
            <div class="v-bios col-md-3 col-sm-12 col-xs-12">
              <div class="image-leader"></div>
              <h4>任旭阳</h4>
              <h5>首席投资顾问</h5>
            </div>
            <div class="v-bios col-md-8 col-sm-12 col-xs-12">
              <div class="text-block" style="width: 100%">
                任旭阳2001年加入百度，工作长达十年，曾任百度市场与商务拓展副总裁，负责公司市场、公关、战略合作、投资并购、政府关系和国际拓展方面工作，直接向CEO汇报。2010年6月，任旭阳离开百度，先赴斯坦福大学攻读管理学硕士，毕业后开始独立创业。十年下来，任旭阳先后和他的搭档们联合创立了一点资讯、News
                Break、海致科技等三家知名公司，以及专注A轮投资的天善资本。2016年，任旭阳还兼任了百度首席顾问。同時作为一点资讯、News
                Break、海致科技等多家知名公司的创始人。
              </div>
            </div>
            <div class="col-md-2"></div> -->
          </div>
        </div>
      </div>
    </PageSection>
    <!--
    <section
      id="section-dyn"
      class="h-viewport"
      v-for="(content， i) in section_contents"
      :key="i"
      v-html="content"
    ></section>-->

    <!-- About--><!--
    <section class="section-timeline" id="about">
      <div class="container-fluid" style="padding: 0; margin: 0">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <h3 style="color: #fff; margin-top: 40px">发展计划</h3>
                </div>
              </div>
            </div>
            <div class="timeline">
              <ol>
                <li>
                  <div>
                    <time>2022</time> 成为全牌照公司，获得 1 号， 6 号和升级 9
                    号牌照 业务包括：IPO、收购合并、 二级市场融资和加密货币基
                    金管理
                  </div>
                </li>
                <li>
                  <div>
                    <time>2023</time> 家族办公室移民、 保险、教育、信托
                    和财富管理
                  </div>
                </li>
                <li>
                  <div><time>2024</time> 上市香港主板</div>
                </li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>-->
    <!-- Services-->
    <!--
    <section class="page-section" id="services">
      <div class="container">
        <h2 class="text-center mt-0">At Your Service</h2>
        <hr class="divider my-4" />
        <div class="row">
          <div class="col-lg-3 col-md-6 text-center">
            <div class="mt-5">
              <i class="fas fa-4x fa-gem text-primary mb-4"></i>
              <h3 class="h4 mb-2">Sturdy Themes</h3>
              <p class="text-muted mb-0">
                Our themes are updated regularly to keep them bug free!
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 text-center">
            <div class="mt-5">
              <i class="fas fa-4x fa-laptop-code text-primary mb-4"></i>
              <h3 class="h4 mb-2">Up to Date</h3>
              <p class="text-muted mb-0">
                All dependencies are kept current to keep things fresh.
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 text-center">
            <div class="mt-5">
              <i class="fas fa-4x fa-globe text-primary mb-4"></i>
              <h3 class="h4 mb-2">Ready to Publish</h3>
              <p class="text-muted mb-0">
                You can use this design as is， or you can make changes!
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 text-center">
            <div class="mt-5">
              <i class="fas fa-4x fa-heart text-primary mb-4"></i>
              <h3 class="h4 mb-2">Made with Love</h3>
              <p class="text-muted mb-0">
                Is it really open source if it's not made with love?
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>-->
    <!-- Contact--><!--
    <section class="page-section" id="contact">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8 text-center">
            <h2 class="mt-0">未來公司架构</h2>
            <hr class="divider my-4" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 ml-auto text-center mb-5 mb-lg-0">
            <img src="/images/anlly/strutcure.png" width="100%" />
          </div>
        </div>
      </div>
    </section>-->

    <section id="section-contact" class="secondary h-viewport">
      <div id="contact" class="h-100">
        <div class="container h-100 section-dynamic align-items-center">
          <div
            class="mh-650 section-content row"
            data-aos="fade-up"
            data-aos-easing="ease-in-back"
            data-aos-anchor="#section-contact"
            data-aos-duration="1100"
            data-aos-offset="0"
            data-aos-anchor-placement="top-center"
          >
            <div class="col-md-6">
              <h1 class="v-bios-heading">联络我们</h1>
              <br/>
              <small style="font-size:18px">公司地址</small><br />
              香港皇后大道中99号中环中心1511室
              <br /><br />
              <small style="font-size:18px">电话</small><br />
              (852) 3169 1716
              <br /><br />
              <!-- <h1 class="v-bios-heading">聯絡我們</h1>
            </div>
            <div class="col-md-6">
              <small>公司地址</small><br />
              香港皇后大道中99號中環中心1511室
              <br /><br />
              <small>電話</small><br />
              (852) 3169 1716
              <br /><br /> -->
            </div>
            <div class="col-md-6"><!--
              <form style="margin-top: 50px">
                <div class="form-group v-fg">
                  <label class="v-label" for="exampleInputEmail1"
                    ><small>您的名字</small></label
                  >
                  <input
                    type="email"
                    class="form-control v-input"
                    id="exampleInputEmail1"
                  />
                  <!-- <small id="emailHelp" class="form-text text-muted"
                    >請填寫您的名字</small
                  > --
                  <small id="emailHelp" class="form-text text-muted"
                    >请填写您的名字</small
                  >
                </div>
                <div class="form-group v-fg">
                  <!-- <label class="v-label" for="exampleInputEmail1"
                    ><small>電郵</small></label
                  >
                   --
<label class="v-label" for="exampleInputEmail1"
                    ><small>电邮</small></label
                  >
                  <input
                    type="email"
                    class="form-control v-input"
                    id="exampleInputEmail1"
                  />
                  <!-- <small id="emailHelp" class="form-text text-muted">請填寫電郵</small> --
                </div>
                <div class="form-group v-fg">
                  <!-- <label class="v-label" for="exampleInputEmail1"
                    ><small>您的主題</small></label
                  > --
                  <label class="v-label" for="exampleInputEmail1"
                    ><small>您的主题</small></label
                  >
                  <input
                    type="email"
                    class="form-control v-input"
                    id="exampleInputEmail1"
                  />
                  <!-- <small id="emailHelp" class="form-text text-muted">請填寫主題</small> --
                </div>
                <div class="form-group v-fg">
                  <!-- <label class="v-label" for="exampleInputEmail1"
                    ><small>內容</small></label
                  >  --
                  <label class="v-label" for="exampleInputEmail1"
                    ><small>內容</small></label
                  >
                  <textarea
                    class="form-control v-input"
                    id="exampleFormControlTextarea1"
                    rows="3"
                  ></textarea>
                  <!-- <small id="emailHelp" class="form-text text-muted">請填寫內容</small> --
                </div>
                <!-- <button type="button" class="btn btn-primary v-button">
                  提交查詢
                </button> --
                <button type="button" class="btn btn-primary v-button">
                  提交查询
                </button>
              </form>-->
            </div>
            <!-- <div class="col-md-2"></div> -->
          </div>
        </div>
      </div>
      <!-- Footer-->
      <footer
        class="position-absolute"
        style="
          left: 0;
          right: 0;
          bottom: 0;
          padding: 1rem;
          font-weight: bold;
        "
      >
        <div class="container">
          <div class="small text-center text-white" style="text-shadow: 0 0 4px black;font-weight: bold;">
            Copyright © 2021 - Anlly Asset Management
          </div>
        </div>
      </footer>
    </section>

    <modal
      v-if="showModal"
      @close="showModal = false"
      data-aos-duration="1100"
      data-aos-offset="0"
      data-aos-anchor-placement="top-bottom"
    >
      <h3 class="v-bios-heading">风险披露 及 免责声明</h3>
      <div class="row">
        <div class="col-md-12" style="padding-top:20px">
          <p>
            投資涉及風險。投資產品的價格波動，有時顯著。投資產品的價格可升可跌，並可能變成毫無價值。證券及其他投資產品的交易可以賺取利潤亦能招致損失。投資者應基於自己的投資經驗，目標和風險承受能力慎重考慮投資產品或本網頁提及的服務是否適合他們。投資者在作出任何投資決定前應仔細閱讀相關證券/投資服務的條款和細則。
          </p>

          <p>
            本網站內所述的任何資料不應被視為亦不構成對任何人，
            在任何司法管轄區的要約或公開發售、也不構成對任何人發出認購任何基金股份的邀請。請注意本公司之基金未經證監會授權於本港向公眾發行。
          </p>

          <p>
            本網頁所提供的資料僅供參考，並不擬提供專業建議，投資者不應就此依賴該等資料進行投資。投資者在決定投資前，
            應徵詢您的投資中介公司的意見並確定相關產品符合自己的 “投資目標”。
          </p>

          <p>
            安立資產管理有限公司(“本公司”)
            已獲香港證券及期貨事務監察委員會發牌及監管。本網站內所述的任何資料僅供參考。在任何情況下，本公司及/或本公司的任何成員皆無須及不會承擔任何直接或間接地因登入或使用本網站或鏈接到本網站的任何網站而造成的任何損失或責任。
          </p>
          <p>本人確認已閱讀並了解以上信息。</p>
          <p>&nbsp;</p>
          <button
            type="button"
            class="btn btn-primary btn-lg v-button mr-1"
            style="margin-top: 1rem; padding-left: 2rem; padding-right: 2rem"
            @click="showModal = false"
          >
            同意
          </button>
          <button
            type="button"
            class="btn btn-secondary btn-lg v-button"
            style="margin-top: 1rem; padding-left: 2rem; padding-right: 2rem"
          >
            不同意
          </button>
          <p>&nbsp;</p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AOS from "aos";
import "aos/dist/aos.css";
import Modal from "../components/Modal";
import PageSection from "../components/PageSection";

export default {
  components: {
    Modal,
    PageSection,
  },

  layout: "basic",

  metaInfo() {
    return { title: this.$t("index") };
  },

  data: () => ({
    showModal: true,
    productSelected: false,
    title: window.config.appName,

    sections: [
      {
        background: "/images/anlly/building.jpg",
        html: `
        <h2 style="color:#FFF">Anlly Asset Management</h2>
        <p style="color:#EEE">A Type 4 & 9 licensed institution of the Securities & Futures Commission of Hong Kong. It has brought considerable returns to professional stakeholders in various fields through the issuance of various fund products and the establishment of high-quality financial platforms.</p>
        `,
      },
      {
        background: "/images/anlly/office1.jpg",
        html: "<p>content</p>",
      },
      {
        background: "/images/anlly/office2.jpg",
        html: "<p>content</p>",
      },
      {
        background: "/images/anlly/office3.jpg",
        html: "<p>content</p>",
      },
    ],

    products: [
      {
        name: "大中华探索收益基金",
        bg_url: "/images/anlly/products/p2_gcb.png",
        color_mask: "rgba(23, 76, 100, 0.7)",
      },
      {
        name: "Pre-IPO／IPO基金",
        bg_url: "/images/anlly/products/p2_ipo.png",
        color_mask: "rgba(89, 119, 176, 0.7)",
      },
      {
        name: "移民基金​",
        bg_url: "/images/anlly/products/p2_mv.png",
        color_mask: "rgba(176, 71, 70, 0.7)",
      },
      // {
      //   name: "加密货币基金",
      //   bg_url: "/images/anlly/products/p_cc.jpg",
      //   color_mask: "rgba(113, 134, 255, 0.7)",
      // },
      {
        name: "独特管道供境外境内投资​",
        bg_url: "/images/anlly/products/s1_sh.jpg",
        color_mask: "rgba(176, 71, 70, 0.7)",
      },
      {
        name: "外部资产管理人业务​",
        bg_url: "/images/anlly/products/s2_sw.jpg",
        color_mask: "rgba(113, 134, 255, 0.7)",
      },
    ],

    services: [
      // {
      //   name: "独特管道供境外境内投资",
      //   bg_url: "/images/anlly/products/p_br.jpg",
      //   color_mask: "rgba(176, 71, 70, 0.7)",
      // },
      // {
      //   name: "外部资产管理人业务",
      //   bg_url: "/images/anlly/products/p_ex.jpg",
      //   color_mask: "rgba(113, 134, 255, 0.7)",
      // },
      {
        name: "独特管道供境外境内投资",
        bg_url: "/images/anlly/products/s1_sh.jpg",
        color_mask: "rgba(176, 71, 70, 0.7)",
      },
      {
        name: "外部资产管理人业务",
        bg_url: "/images/anlly/products/s2_sw.jpg",
        color_mask: "rgba(113, 134, 255, 0.7)",
      },
    ],
  }),

  computed: {
    ...mapGetters({
      authenticated: "auth/check",
    }),
    section_contents: function () {
      let content = this.sections.map((s) => {
        let wrap = `
            <div class="h-100"
                style="
                    background-image: url(${s.background});
                    background-size: cover;
                    background-position: center center;
                    position: relative;
                    overflow: hidden;
                ">
                <div
                class="
                    container
                    h-100
                    section-dynamic
                    align-items-center
                ">
                    <div class="section-content pt-5 p-4" style="width: 75%;">{{}}</div>
                </div>
            </div>
        `
          .replace(/\s+/g, " ")
          .trim();
        wrap = wrap.replace(/{{.*}}/g, s.html);
        console.log(wrap);
        return "" + wrap;
      });
      return content;
    },
  },

  created() {},

  mounted() {
    var myvid = this.$refs.video;
    // myvid.addEventListener("ended", function (e) {
    //   // get the active source and the next video source.
    //   // I set it so if there's no next, it loops to the first one
    //   var activesource = document.querySelector("#video-bg source.active");
    //   var nextsource =
    //     document.querySelector("#video-bg source.active + source") ||
    //     document.querySelector("#video-bg source:first-child");

    //   // deactivate current source, and activate next one
    //   activesource.className = "";
    //   nextsource.className = "active";

    //   // update the video source and play
    //   myvid.src = nextsource.src;
    //   myvid.play();
    // });

    //this.$refs.popup.click()
    //this.showModal = true
    document.getElementById("vendor-app").style.height = "100vh";
    document.getElementById("vendor-app").style.overflow = "hidden";
  },

  watch: {
    showModal: function () {
      console.log("change");
      if (this.showModal) {
        document.getElementById("vendor-app").style.height = "100%";
        document.getElementById("vendor-app").style.overflow = "hidden";
        return;
      }

      AOS.init();
      window.AOS = AOS;
      document.getElementById("vendor-app").style.height = "auto";
      document.getElementById("vendor-app").style.overflow = "auto";
    },
  },

  method: {
    getSection: function (s) {
      let wrap = `
            <div class="h-100"
                style="
                    background-image: url(${s.background});
                    background-size: cover;
                    background-position: center center;
                    position: relative;
                    overflow: hidden;
                ">
                <div
                class="
                    container
                    h-100
                    section-dynamic
                    align-items-center
                ">
                    <div class="section-content pt-5 p-4" style="width: 75%;">{{}}</div>
                </div>
            </div>
        `
        .replace(/\s+/g, " ")
        .trim();
      wrap = wrap.replace(/{{.*}}/g, s.html);
      console.log(wrap);
      return "" + wrap;
    },
  },
};
</script>

<style scoped>
.top-right {
  position: absolute;
  right: 10px;
  top: 18px;
}

.title {
  font-size: 85px;
}
</style>
