<template>
  <section
    :id="id"
    v-bind:class="{ secondary: !primary, 'h-viewport': h100 }"
    :style="`
    background-color         : radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(218, 236, 255, 1) 100%, rgba(255, 255, 255, 1) 100%);
    background-image   : url(${bg});
    background-position: right 50%;
    background-size    : cover;
    background-repeat  : no-repeat;
    ${
      fixed
        ? `
      position: fixed;
      top:0;
      left:0;
      right:0;
      bottom:0; 
      z-index: 1040;
    `
        : ''
    }
  `"
  >
    
    <slot />
    <div class="h-100">
      <div v-if="nextSection" class="page-down">
        <a :href="nextSection">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M12.51,8.7,10,11,7.49,8.7a.51.51,0,0,0-.72,0,.5.5,0,0,0,0,.71l2.86,2.81a.51.51,0,0,0,.72,0l2.86-2.81a.5.5,0,0,0,0-.71A.51.51,0,0,0,12.51,8.7ZM10,.4A9.6,9.6,0,1,0,19.6,10,9.6,9.6,0,0,0,10,.4Zm0,18A8.35,8.35,0,1,1,18.35,10,8.35,8.35,0,0,1,10,18.35Z"
              fill="#FFFFFFAA"
            ></path>
          </svg>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    id: String,

    /* style */
    primary: {
      type: Boolean,
      default: false,
    },
    h100: {
      type: Boolean,
      default: true,
    },
    nextSection: {
      type: String,
    },
    bg: {
      type: String,
    },
    backButton: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  mounted(){
    console.log(this)
  }
};
</script>
